import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";

const Logo1 = ({ className, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
      <a href="https://mobiezy.com/" target="_blank">
          <Image
            src={require("./../../../assets/images/mobiezy1.png")}
            alt="Open"
            width={180}
            height={120}
          />{" "}
        </a>
      </h1>
    </div>
  );
};

export default Logo1;
