import React, { useState, useEffect } from "react";
import { SectionProps } from "../../utils/SectionProps";
import "react-awesome-slider/dist/styles.css";
import Button from "../elements/Button";

import AuthApi1 from "../services/authApi";
import queryString from "query-string";
import { useLocation, Link } from "react-router-dom";

import swal from 'sweetalert';
const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Response = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const { search } = useLocation();
  const { custNumber } = queryString.parse(search);
  // let encoded = base64encode(custNumber);

  console.log(custNumber);
 

  useEffect(() => {
    //razorflag();
    if (custNumber === undefined) {
      console.log('variable is undefined'); 
      
    swal("Oops", "Something Went wrong", "error")
      
    }
    else
    {
        
    swal("Success", "Your Payment is Successful, Please wait 5-10 minutes we are processing your request.", "success")
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activate = async () => {
    let userData = {
      lcousername: localStorage.getItem("Merchant_Salt"),
      nxt_id:localStorage.getItem("Merchant_Salt"),
      owner_id:localStorage.getItem("operatorId"),
      cust_num:localStorage.getItem("custNum"),
      stb_id:custNumber

    };//
    console.log(JSON.stringify(userData));
    await AuthApi1.active(JSON.stringify(userData))
      .then((response) => {
        console.log(response);
         
    swal("success", "Your Request has been successfully submitted", "success")
        // isLoading = false;

       
      })
      .catch((e) => {
        alert("error in getting details");
        console.log(e); 
      });

  }  

  return (
   
    <center>
   

      <div className="container-sm">

        <div className="hero-content">
          <div style={{ marginTop: '60px' }} >
          <div className="logo">
          <img src={require("../../assets/images/mpayReward.png")} width="600" />
        </div>
        <br/>
        <Button
                    tag="a"
                    color="primary"
                    wideMobile
                    href="https://play.google.com/store/apps/details?id=com.mobiezy.cablecustomer"
                    
                  >
                   Download For Android
                   
                  </Button>
                  
          </div>
          <br/>
          <Button
                    tag="a"
                    color="primary"
                    wideMobile
                href="https://apps.apple.com/in/app/mobiezy/id1531512582?platform=iphone"
                    
                  >
                   Download For IOS
                    
                  </Button>
        </div>
      </div>
   
  </center> 
 
)};

Response.propTypes = propTypes;
Response.defaultProps = defaultProps;

export default Response;
