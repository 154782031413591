import React, { useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { FaCartPlus } from "react-icons/fa";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import "../../assets/styles/Channels.css";

const ChannelCard = ({ channel, handleAddRemove, selected }) => {
    const [added, setAdded] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [selectedC, setSelectedC] = useState(selected);

    const handleClick = () => {
      console.log("----------------------------------------------------------------");
      console.log("Channel Details:",channel);
      console.log("----------------------------------------------------------------");
      setAdded(!added);
      handleAddRemove(channel);
    };

    useEffect(() => {
      setAdded(selectedC.some(c => c.pack_id === channel.pack_id));
    }, [selectedC, channel]);
  
    return (
      <>
        {isSmallScreen ?
        //---------------------------Old Design without channel icon--------------------------------------
        /*<div style={{width: "95%", display: "flex", flexDirection: 'row', justifyContent: "center", alignItems: "center", gap: "10px" ,border: '1px solid white', borderRadius: "15px", padding: "5px"}}>
            <table style={{marginBottom: "0px"}}>
                <td style={{width: "70%"}}>
                    {channel.pack_name}
                </td>
                <td style={{width: "10%", textAlign: "left"}}>
                    ₹{channel.total_amount}
                </td>
                <td style={{width: "20%"}}>
                    <button onClick={handleClick} style={{padding: "10px", border: "0px solid white", borderRadius: "15px", backgroundColor: "white", color: "black", fontSize: "12px", backgroundColor: added ? "red" : "green", color: "white"}}>
                        {added ? <MdDelete /> : <FaCartPlus />}
                    </button>
                </td>
            </table>
        </div>*/
        <div style={{width: "95%", display: "flex", flexDirection: 'row', justifyContent: "center", alignItems: "center", gap: "10px" ,border: '1px solid white', borderRadius: "15px", padding: "5px"}}>
            <table style={{marginBottom: "0px"}}>
              <tbody>
                <tr>
                  <td style={{width: "20%"}}>
                    <center>
                      <div className="channel-icon-container-sml">
                        <img className="img-channel-icon-sml" src={channel.icon} alt="icon"/>
                      </div>
                    </center>
                  </td>
                  <td style={{width: "60%"}}>
                    <div style={{textAlign: "center"}}>
                        {channel.pack_name}
                    </div>
                  </td>
                  <td style={{width: "20%"}}>
                      <button className= {added? "channel-remove-button" : "channel-add-button"} onClick={handleClick}>
                          {added ? <MdDelete style={{fontSize: "18px"}} /> : <FaCartPlus style={{fontSize: "18px"}} />}
                      </button>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" className="channel-tbl-rate-clm">
                    ₹{channel.total_amount}
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
        : 
        <div style={{width: "90%", display: "flex", flexDirection: 'row', justifyContent: "center", alignItems: "center", gap: "10px" ,border: '1px solid white', borderRadius: "15px", padding: "5px"}}>
            <table style={{marginBottom: "0px"}}>
              <tbody>
                <tr>
                  <td style={{width: "10%"}}>
                    <div className="channel-icon-container">
                      <img className="img-channel-icon" src={channel.icon} alt="icon"/>
                    </div>
                  </td>
                  <td style={{width: "80%"}}>
                    <div style={{display: "flex", justifyContent: "space-around"}}>
                        {channel.pack_name}
                    </div>
                  </td>
                  <td style={{width: "10%"}}>
                    <center>
                      <button onClick={handleClick} style={{padding: "10px", border: "0px solid white", borderRadius: "15px", backgroundColor: "white", color: "black", backgroundColor: added ? "red" : "green", color: "white"}}>
                          {added ? <MdDelete style={{fontSize: "20px"}} /> : <FaCartPlus style={{fontSize: "20px"}} />}
                      </button>
                    </center>
                  </td>
                </tr>
                <tr>
                  <td className="channel-tbl-rate-clm" colSpan="3">
                      ₹{channel.total_amount}
                  </td>
                </tr>
              </tbody>
            </table>
        </div> 
        //----------------------------Grid Design------------------------------------------
        /*<div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px'}}>
            <div style={{border: '1px solid white', borderRadius: '15px', padding: '5px'}}>
              <table>
                <tbody>
                  <tr>
                    <td colSpan="2" style={{textAlign: "center"}}>
                      {channel.pack_name}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="channel-icon-container">
                          <img className="img-channel-icon" src={channel.icon} alt="icon"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "70%", backgroundColor: "orangered", color: "white"}}>
                      <div style={{textAlign: "center"}}>
                          ₹{channel.total_amount}
                      </div>
                    </td>
                    <td>
                      <center>
                          <button onClick={handleClick} style={{padding: '10px', border: '0px solid white', borderRadius: '15px', backgroundColor: added ? 'red' : 'green', color: 'white'}}>
                              {added ? <MdDelete style={{fontSize: '20px'}} /> : <FaCartPlus style={{fontSize: '20px'}} />}
                          </button>
                      </center>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>*/
      }
      </>
    );
};

const ChannelContainer = ({ channels, onChannelsChange, selectedChannel, cartTotalAmt, onTotalChange}) => {
  const [selectedChannels, setSelectedChannels] = useState(selectedChannel);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    onChannelsChange(selectedChannels)
  }, [selectedChannels, onChannelsChange])

  const handleAddRemove = (channel) => {
    if(selectedChannels.some(c => c.pack_id === channel.pack_id)) {
      setSelectedChannels(selectedChannels.filter(c => c.pack_id !== channel.pack_id));
      const total = parseFloat(cartTotalAmt) - parseFloat(channel.total_amount);
      onTotalChange(total);
    } else {
      setSelectedChannels([...selectedChannels, channel]);
      const total = parseFloat(cartTotalAmt) + parseFloat(channel.total_amount);
      onTotalChange(total);
    }
  };

  return (
    <div className={isSmallScreen? "channels-scroll-inner-container-mobile" : "channels-scroll-inner-container-web"}>
      {channels && channels.map(channel => (
        <ChannelCard
          key={channel.pack_id}
          channel={channel}
          selected={selectedChannels}
          handleAddRemove={handleAddRemove}
        />
      ))}
    </div>
  );
};

export default ChannelContainer

