import React from 'react';
import { useState, useEffect } from 'react';
import AddChannelHeader from '../components/layout/AddChannelHeader';
import Footer from '../components/layout/Footer';
import Channels from '../components/elements/Channels';
import { FaShoppingCart } from "react-icons/fa";
import authApi3 from '../components/services/authApi';
import authApi from '../components/services/authApi';
import authApi4 from '../components/services/authApi';
import Loader from "react-js-loader";
import swal from 'sweetalert';
import { useMediaQuery } from '@mui/material';
import "../assets/styles/Channels.css";
import { compileString } from 'sass';
import Button from "../components/elements/Button";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from "@mui/material";

export default function AddChannel() {
  const [selectedChannelType, setSelectedChannelType] = useState('');
  const [allChannels, setAllChannels] = useState([]);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [opDialogOpen, setOpDialogOpen] = useState(false);

  const handleOpDialogOpen = () => {
    setOpDialogOpen(true);
  }

  const handleOpDialogClose = () => {
    setOpDialogOpen(false);
  }

  useEffect(() => {
    setIsLoading(true);
    let isMount = true;
    const fetchChannels = async() => {
      let isMount = true;
      let data = {
        records:[
          {
              operator_id: localStorage.getItem("operatorId"),
              cust_num: localStorage.getItem("custNum")
          }
        ]
      }
      await authApi3.fetchChannels(data)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if(isMount){
            setAllChannels(res.data.AddChannelList);
          }
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log("Failed to fetch channels :",e);
        setIsLoading(false);
      })
    }

    const fetchDataAndUpdate = async () => {
     await fetchChannels();
     if (isMount) {
        setFilteredChannels(allChannels.filter(channel => channel.pkgtype === selectedChannelType));
      }
    }
    fetchDataAndUpdate();
    return() => {
      isMount = false;
    }
  }, [selectedChannelType]);

  const GetStbCount = () => {
    setIsLoading(true);
    let data = JSON.stringify({
        records: [  
            {
                operator_id: localStorage.getItem("operatorId"),
                cust_num: localStorage.getItem("custNum")
            }
        ]
    })

    console.log("Request Body :", data);

    authApi4.stbCount(data).then((res) => {
      console.log("STB Count :",res);
      if(res.status === 200){
        if(res.data.stb_count === 1){
          PaymentCheckout();
        }else {
            setIsLoading(false);
            swal("Error","Multiple STB!, Please contact your operator.", "warning");
        }
      }else{
        setIsLoading(false);
        swal("Error","The server could not be reached, Please try again.", "warning");
      }
    })
    .catch((e) => {
      setIsLoading(false);
      console.log("Encountered error while fetching STB Count:",e);
      swal("Error","The server could not be reached, Please try again.", "warning");
    })
  }

  const PaymentCheckout = async() => {
    let newDate = new Date();
    let getYear = newDate.getFullYear().toString();
    let getMonth = newDate.getMonth().toString();
    let getDate = newDate.getDate().toString();
    let getMSec = newDate.getMilliseconds().toString();
    let combineDate = getYear + getMonth + getDate + getMSec;
    let tranRef = "ADCNL" + localStorage.getItem("custNum") + combineDate;

    //-----------Multiple STB Handling-----------------
    // let item = localStorage.getItem("stbNumber");
    // let StbNum = item && item.includes(',') ? "" : item;
    // console.log("STB Number without seperation:",item);
    // console.log("STB Number: ",StbNum);

    // if (!StbNum) {
    //   console.log('StbId is empty, aborting function execution');
    //   setIsLoading(false);
    //   swal("Error","Multiple STB!, Please contact your operator.", "warning");
    //   return;
    // }
    //--------------------------------------------------

    //let CartTotal = Math.round(cartTotal);
    let CartTotal=parseFloat(cartTotal.toFixed(2));
    if (CartTotal<1) {
      console.log('Cart Total is :',CartTotal);
      setIsLoading(false);
      swal("Error","Amount should be more than zero!", "warning");
      return;
    }

    let data = JSON.stringify({
      OPERATOR_ID: localStorage.getItem("operatorId"),
      AMOUNT: cartTotal.toFixed(2),
      CUST_NUM: localStorage.getItem("custNum"),
      tran_id: tranRef,
      PHONE: localStorage.getItem("phone"),
      customerName: localStorage.getItem("custName"),
      Channel: selectedChannels, 
      mso_id: localStorage.getItem("MSO_ID"),
      stb_id: localStorage.getItem("STB_ID"),
      type: "Add Channel",
      flag: "A",
    });

    // console.log("------------------------Request Body---------------------------");
    // console.log(data);
    // console.log("---------------------------------------------------------------");

    await authApi.cashfree_add(data).then((res) => {
      if(res.status === 200){
        console.log("Cashfree Response :",res);
        if (res.data.payment_session_id !== "") {
          window.location.href =
            "https://apps.mobiezy.in/CASHFREE/INTEGRATED_ADD/cashfree_ui.php?paymentSessionId=" +
            res.data.payment_session_id;
          // setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log("Cashfree token generation error:",res.data.p_out_mssg);
          swal("Error",res.data.p_out_mssg, "warning");
        }
      }else{
        console.log("Response Status Code :",res.status);
        swal("Error","We have encountered a problem, Please try again.", "warning");
      }
    })
    .catch((e)=> {
      console.log("Inside Catch of Cashfree API, Error:",e);
      swal("Error","The server could not be reached, Please try again.", "warning");
      setIsLoading(false);
    })
  }

  const Loading=() => {
    return(
        <center>
          <div className="container-sm">
            <div className="hero-content">
              <div style={{ marginTop: "120px", position: "relative" }}>
                <Loader
                  type="box-rectangular"
                  bgColor={"#FFA500"}
                  color={"#FFA500"}
                  size={80}
                />
              </div>
              <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1,
                backgroundColor: "rgba(0,0,0,0.5)"
              }}/>
            </div>
          </div>
      </center>
    );
  };


  return (
    <>
    <AddChannelHeader title={"Add Channel"} onContactBtnClick={handleOpDialogOpen}/>
    <center>
    <section className="hero section center-content">
      <div className="container-sm">
        <div className="hero-content">
          <div style={{ marginTop: "120px" }}>
            {/* Channel Type Dropdown */}
            <select className='channel-typ-dropdown' style={{padding: isSmallScreen?"10px":"15px"}}
              onChange={(e) => {setSelectedChannelType(e.target.value); setSearchTerm('');}}
            >
              <option style={{color: "black"}} value="">Select Channel Type</option>
              <option style={{color: "black"}} value="A">MSO Bouquet</option>
              <option style={{color: "black"}} value="C">Pay Channel</option>
            </select>
            {/* Search Bar  */}
            <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: "10px"
            }}>
              <input type='text' 
                placeholder='Channel Search' 
                style={{
                  width: "90%", 
                  background: "transparent", 
                  color: "white", 
                  padding: isSmallScreen?"10px":"15px", 
                  fontSize: "15px",
                  border: "1px solid white",
                  borderRadius: "15px"
                }}
                value={searchTerm}
                onChange={(e) => {setSearchTerm(e.target.value); console.log("Search Item:",e.target.value);}}
              />
            </div>
            {/* Channel Container */}
            <hr className='horizontal-row' />
            {isLoading? <Loading /> :
            <center>
              <div className="channels-scroll-container">
                  <Channels channels={filteredChannels.filter(channel => channel.pack_name.toLowerCase().includes(searchTerm.toLowerCase()))} onChannelsChange={setSelectedChannels} selectedChannel={selectedChannels} cartTotalAmt={cartTotal} onTotalChange={setCartTotal} />
              </div>
            </center>
            }
            <hr className='horizontal-row' />
            <div style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px"
            }}>
              <div style={{
                width: "90%",
                backgroundColor: "transparent",
                border: "1px solid white",
                borderRadius: "15px",
                padding: "10px",
                display: "flex",
                flexWrap: "wrap"
              }}
              >
                <div style={{
                  width: isSmallScreen? "20%":"10%",
                  display: "flex",
                  justifyContent: "space-around"
                }}>
                  <FaShoppingCart />
                </div>
                <div style={{
                  width: isSmallScreen?"80%":"90%",
                  textAlign: "left"
                }}>
                  Cart Total : ₹{cartTotal.toFixed(2)}
                </div>
              </div>
            </div>
            <div>
              <button className='channel-ckut-btn' 
                onClick={() => {GetStbCount()}}>
                  Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={opDialogOpen}
      >
        <DialogTitle>
          <Typography variant="h6" color={"black"} align="center">
            Contact Info
          </Typography>
        </DialogTitle>
        <DialogContent align="left">
        <DialogContentText>
          Network Name:&nbsp;{localStorage.getItem("operatorName")}
          </DialogContentText>
          <DialogContentText>
          Phone&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
            {localStorage.getItem("op_phone") ? (
              <a href={`tel:${localStorage.getItem("op_phone")}`} style={{color:"#9CA9B3"}}>{localStorage.getItem("op_phone")}</a>
            ) : (
              ''
            )}
          </DialogContentText>
          <DialogContentText>
              Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
              {localStorage.getItem("op_email") ? (
                <a href={`mailto:${localStorage.getItem("op_email")}`} style={{color:"#9CA9B3"}}>{localStorage.getItem("op_email")}</a>
              ) : (
                ''
              )}
          </DialogContentText>
          <DialogContentText>
            <div style={{ display: 'flex', alignItems: 'right', width: '100%' }}>
              <span style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>Address&nbsp;:</span>
              <div style={{ flexGrow: 1,color:"#9CA9B3"}}>
                
                  {localStorage.getItem("op_address")}
               
              </div>
            </div>
            
          </DialogContentText>
          <DialogActions>
            <Button 
                style={{width: '100%', backgroundColor: '#1CB68B', color: 'white', border: '0px solid green', borderRadius: '15px'}} 
                onClick={() => { handleOpDialogClose() }} onClose={() => { handleOpDialogClose() }}
            >Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </section>
  </center>
  <hr />
  <Footer />
  </>
  )
}
