import React from "react";
// import sections
import Hero from "../components/sections/Hero";

const Home = () => {
  return (
    <>
      <Hero className="illustration-section-01" />
      
    </>
  );
};

export default Home;
